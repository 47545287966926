import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "accounts",
  initialState: {
    loading: false,
    fetched: false,
    total: 0,
    list: [],
    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    getSuccess: (state, action) => {
      const { data, total } = action.payload;
      state.total = total;
      state.list = [...state.list, ...data];
      state.loading = false;
      state.fetched = true;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createSuccess: (state, action) => {
      const { _id, account } = action.payload;
      if (_id) {
        state.list = state.list.map((item) => {
          if (item._id === _id) {
            return account;
          }
          return item;
        });
      } else {
        state.list.unshift(account);
        state.total += 1;
      }
      state.loading = false;
    },
  },
});
export default slice.reducer;

export const { setLoading } = slice.actions;

export const getAccounts =
  ({ page }) =>
  (dispatch, getState) => {
    if (!getState().accounts.fetched) {
      dispatch(setLoading(true));
    }
    dispatch({
      type: "apiCall",
      payload: {
        url: `/accounts?page=${page}`,
        method: "get",
        onSuccess: "accounts/getSuccess",
        onError: "accounts/getError",
      },
    });
  };

export const createAccount = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/accounts",
      method: "post",
      data,
      onSuccess: "accounts/createSuccess",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import api from "./middlewares/api";
import users from "./users";

import accounts from "./accounts";
import specialties from "./specialties";
import interviewees from "./interviewees";
import testforms from "./testforms";
import testdata from "./testdata";

const reducers = combineReducers({
  users,
  accounts,
  specialties,
  interviewees,
  testforms,
  testdata,
});
const rootReducer = (state, action) => {
  if (action.type === "users/logoutSuccess") {
    localStorage.clear();
    state = undefined;
  }

  return reducers(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["apiCall"],
      },
    }),
    api,
  ],
});

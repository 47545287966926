import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "testforms",
  initialState: {},
  reducers: {
    initializeNewItem: (state, action) => {
      state[action.payload] = {
        loading: true,
        fetched: false,
        total: 0,
        list: [],
        error: null,
      };
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state[key].loading = value;
      state[key].error = null;
    },

    getSuccess: (state, action) => {
      const { _id, items } = action.payload;

      state[_id].list = items;
      state[_id].loading = false;
      state[_id].fetched = true;
    },
    getError: (state, action) => {
      const { error } = action.payload;
      state[error._id].loading = false;
      state[error._id].error = error;
    },
    createSuccess: (state, action) => {
      const { _id, items } = action.payload;
      state[_id].list = items;
    },
  },
});
export default slice.reducer;

export const { initializeNewItem, setLoading, resetData } = slice.actions;

export const getTestform =
  ({ _id }) =>
  (dispatch, getState) => {
    if (!getState().testforms[_id]) {
      dispatch(initializeNewItem(_id));
    }

    dispatch({
      type: "apiCall",
      payload: {
        url: `/testforms/${_id}`,
        method: "get",
        onSuccess: "testforms/getSuccess",
        onError: "testforms/getError",
      },
    });
  };

export const createTestform = (data, response) => (dispatch) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/testforms",
      method: "post",
      data,
      onSuccess: "testforms/createSuccess",
      onError: "testforms/createError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};

// export const deleteWarehouseMaterial = (data, response) => (dispatch) => {
//   dispatch({
//     type: "apiCall",
//     payload: {
//       url: "/warehouseMaterial",
//       method: "delete",
//       data,
//       onSuccess: "warehouseMaterial/deleteSuccess",
//       onError: "warehouseMaterial/deleteError",
//     },
//   })
//     .then((e) => response(e))
//     .catch((e) => response(e));
// };

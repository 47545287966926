import React, { Suspense, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
function ProtectedRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.currentUser) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
}

export default ProtectedRoute;

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "testdata",
  initialState: {
    loading: false,
    fetched: false,
    total: 0,
    list: [],
    error: null,
    filter: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
      state.list = [];
    },

    resetData: (state, action) => {
      state.error = null;
      state.total = 0;
      state.fetched = false;
      state.list = [];
      state.loading = true;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.value;
    },
    getSuccess: (state, action) => {
      const { data, total } = action.payload;
      state.total = total;
      state.list = data;
      state.loading = false;
      state.fetched = true;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createSuccess: (state, action) => {
      state.list.unshift(action.payload);
      state.total += 1;
    },
    createError: (state, action) => {
      // state.error = action.payload.error;
    },
  },
});
export default slice.reducer;

export const { setLoading, resetData, setFilter } = slice.actions;

export const getTestData =
  ({ page = 0, filter }) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/interviews?page=${page}&&${filter}`,
        method: "get",
        onSuccess: "testdata/getSuccess",
        onError: "testdata/getError",
      },
    });
  };

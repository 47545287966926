import React from "react";

import Box from "@mui/material/Box";

import { Outlet } from "react-router-dom";

function App() {
  return (
    <Box height="100vh">
      <Outlet />
    </Box>
  );
}

export default App;

import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import AuthProvider from "./context/AuthProvider";
import Root from "./routes/Root";

const Home = React.lazy(() => import("./routes/home"));
const Acounts = React.lazy(() => import("./routes/acounts"));
const Specialties = React.lazy(() => import("./routes/specialties"));
const Interviewees = React.lazy(() => import("./routes/interviewees"));
const TestForm = React.lazy(() => import("./routes/testform"));
const Interview = React.lazy(() => import("./routes/interview"));
const TestData = React.lazy(() => import("./routes/testdata"));
const PreviewInterviewee = React.lazy(() =>
  import("./routes/interviewees/preview.js")
);

const CreateAccount = React.lazy(() => import("./routes/acounts/create"));
const CreateSpecialty = React.lazy(() => import("./routes/specialties/create"));
const CreateInterviewee = React.lazy(() =>
  import("./routes/interviewees/create")
);

const Login = React.lazy(() => import("./routes/login"));

function App() {
  const location = useLocation();
  const state = location.state;
  return (
    <AuthProvider>
      <Routes location={state?.backgroundLocation || location}>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Root />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/accounts" element={<Acounts />} />
          <Route path="/specialties" element={<Specialties />} />
          <Route path="/specialties/:_id/testform" element={<TestForm />} />
          <Route path="/interviewees" element={<Interviewees />} />
          <Route path="/interviewees/:_id" element={<PreviewInterviewee />} />
          <Route path="/interview/:_id" element={<Interview />} />
          <Route path="/testdata" element={<TestData />} />
        </Route>

        <Route
          path="/login"
          element={
            <Suspense fallback={<div>loader</div>}>
              <Login />
            </Suspense>
          }
        />
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route
            path="/accounts/create"
            element={
              <ProtectedRoute>
                <CreateAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/specialties/create"
            element={
              <ProtectedRoute>
                <CreateSpecialty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/interviewees/create"
            element={
              <ProtectedRoute>
                <CreateInterviewee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/specialties/:_id/testform"
            element={
              <ProtectedRoute>
                <TestForm />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
    </AuthProvider>
  );
}

export default App;
